<template>
  <template v-if="global.state.isMobile">
    <mobile-about />
    <mobile-info />
    <mobile-features :active-testimonial="activeTestimonial" />
    <mobile-testimonials  @active-testimonial-change="changeTestimonial" />
    <mobile-blog />
    <mobile-banner />
  </template>
  <template v-else>
    <features-section :active-testimonial="activeTestimonial" />
    <reviews-section @active-testimonial-change="changeTestimonial" />
    <blog-section />
    <homepage-banner />
  </template>

</template>

<script>
import { ref, inject, defineAsyncComponent } from "vue";

export default {
  name: "ScannerSections",
  components: {
    "features-section": defineAsyncComponent(() => import("@/pages/web/sections/Features")),
    "reviews-section": defineAsyncComponent(() => import("@/pages/web/sections/Reviews")),
    "blog-section": defineAsyncComponent(() => import("@/pages/web/sections/Blog")),
    "homepage-banner": defineAsyncComponent(() => import("@/components/web/HomepageBanner")),
    "mobile-about": defineAsyncComponent(() => import("@/pages/mobile/sections/About")),
    "mobile-features": defineAsyncComponent(() => import("@/pages/mobile/sections/Features")),
    "mobile-info": defineAsyncComponent(() => import("@/pages/mobile/sections/Info")),
    "mobile-testimonials": defineAsyncComponent(() => import("@/pages/mobile/sections/Testimonials")),
    "mobile-blog": defineAsyncComponent(() => import("@/pages/mobile/sections/Blog")),
    "mobile-banner": defineAsyncComponent(() => import("@/components/mobile/HomepageBanner")),

  },
  setup() {
    const global = inject("global")

    const activeTestimonial = ref({
      title: "",
      img: "",
      name: "",
      content: "",
      rating: 0
    })

    const changeTestimonial = testimonial => {
      activeTestimonial.value = testimonial
    }

    return {
      activeTestimonial,
      changeTestimonial,
      global
    }
  }
}
</script>

<style scoped>

</style>